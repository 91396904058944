import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo"

import "../styles/main.scss"

const About = () => (
  <Layout headline="Things to know about me">
    <SEO siteTitle="About"/>
    
    <article>
    <p>
      I'm a web design lover, trapped in an engineer's body. 
      I design and code for fun. I also try to write down everything that´s on my mind.
      <br /><br />
      During the day I develop digital solutions around the topic IoT. 
      As soon as it gets dark I dedicate myself to everything around web design and web development. 
    </p>
      
    <p>
      My great passion is to design and develop individual websites, blogs and design pattern libraries for people 
      who don´t want their product to be mainstream. The used toolkit is simple HTML, CSS, and JS.
      Some years ago I started to teach myself web development. 
      My biggest help at that time was Travis Neilson, who was 
      still running his Youtube channel <a href="https://www.youtube.com/user/DevTipsForDesigners" rel="noopener noreferrer" target="_blank">DevTips</a>  at that time. 
      I check his <a href="http://www.travisneilson.com/" rel="noopener noreferrer" target="_blank">website</a> every now and then to see what's new with him.
    </p>
    <br />
    <p>
      The first websites were created with <a href="https://jekyllrb.com/" rel="noopener noreferrer" target="_blank">Jekyll</a>, a static site generator that allows you to use a file-based CMS. 
      Therefore no database is necessary. After a training phase, the learning curve is quite steep and you quickly 
      come to great results.<br /><br />
      At the moment I give <a href="https://www.gatsbyjs.org/" rel="noopener noreferrer" target="_blank">GatsbyJS</a> a try because I am always looking for new tools, which will take me a step further.
    </p>
    <br />
    <p>
      On <a href="https://medium.com/@mychriz" rel="noopener noreferrer" target="_blank">Medium</a>, I write about everything that interests me, nutrition, my <a href="https://www.schindelhauerbikes.com" rel="noopener noreferrer" target="_blank">Schindelhauer</a> bicycle, my <a href="https://www.instagram.com/fipsthesheltie/?hl=de" rel="noopener noreferrer" target="_blank"> dog </a> and <a href="https://athayoga-wuppertal.de/" rel="noopener noreferrer" target="_blank"> yoga</a>.
    </p>
    <br />
    <Link to="/">back home</Link>
    </article>
  </Layout>
)

export default About
